import { CONSENT_MANAGER_COOKIE_NAME } from '@base/constants/consent-manager'

export default defineNuxtRouteMiddleware((_) => {
  const nuxtApp = useNuxtApp()
  const userConsent = useConsentState()
  const modalState = useConsentModalState()
  const { getConsentForPurpose, isConsentValid } = useConsentManager()

  if (nuxtApp.payload.serverRendered) {
    const cookieHeader = useRequestHeaders(['cookie'])

    const cookies = cookieHeader?.cookie
      ?.split(';')
      .reduce((acc: { [key: string]: string }, cookie) => {
        const [key, value] = cookie.split('=').map((c) => c.trim())
        acc[key] = decodeURIComponent(value)
        return acc
      }, {})

    if (cookies?.[CONSENT_MANAGER_COOKIE_NAME]) {
      const consent = cookies?.[CONSENT_MANAGER_COOKIE_NAME]?.split(',')
      const isValidFormat = consent.every((value) => !isNaN(Number(value)))
      const isValidVendors = isConsentValid()

      if (isValidFormat && isValidVendors) {
        userConsent.value = consent
        modalState.value.isInitialModalVisible = false
      } else {
        userConsent.value = undefined
        modalState.value.isInitialModalVisible = true
      }
    }

    // watch for consent changes, watch is defined here to avoid multiple watches when useConsentManger is called multiple times
    watch(userConsent, () => {
      if (!nuxtApp.$gtm) {
        return
      }

      nuxtApp.$gtm.push({
        event: DATA_LAYER_EVENTS.USER_CONSENT_UPDATE,
        [DATA_LAYER_KEYS.USER_CONSENT]: userConsent.value?.join(','),
        [DATA_LAYER_KEYS.USER_CONSENT_FUNCTIONAL]: getConsentForPurpose(
          CONSENT_MANAGER_PURPOSE.FUNCTIONAL
        )
          ? 'granted'
          : 'denied',
        [DATA_LAYER_KEYS.USER_CONSENT_ADVERTISING]: getConsentForPurpose(
          CONSENT_MANAGER_PURPOSE.ADVERTISING
        )
          ? 'granted'
          : 'denied',
      })
    })
  }
})
