import { default as _91_46_46_46pageSlug_93sCCzpmdmgKMeta } from "/home/forge/deployments/sitefactory/dev/2024-12-11_15-58-00_1d1561e/layers/cms/pages/[...pageSlug].vue?macro=true";
import { default as activateAAEFkgBey5Meta } from "/home/forge/deployments/sitefactory/dev/2024-12-11_15-58-00_1d1561e/layers/base/pages/account/activate.vue?macro=true";
import { default as indexDlRoEIzL1AMeta } from "/home/forge/deployments/sitefactory/dev/2024-12-11_15-58-00_1d1561e/layers/base/pages/account/index.vue?macro=true";
import { default as _91securityKey_93VbdpR9Lw3oMeta } from "/home/forge/deployments/sitefactory/dev/2024-12-11_15-58-00_1d1561e/layers/base/pages/account/order/[orderNumber]/[securityKey].vue?macro=true";
import { default as newuvitoHM1eGMeta } from "/home/forge/deployments/sitefactory/dev/2024-12-11_15-58-00_1d1561e/layers/base/pages/account/password/new.vue?macro=true";
import { default as watchlistlB4jln5wbpMeta } from "/home/forge/deployments/sitefactory/dev/2024-12-11_15-58-00_1d1561e/layers/base/pages/account/watchlist.vue?macro=true";
import { default as indexjOYkhPdoREMeta } from "/home/forge/deployments/sitefactory/dev/2024-12-11_15-58-00_1d1561e/layers/builder/pages/admin/index.vue?macro=true";
import { default as editYAmfzVDVRGMeta } from "/home/forge/deployments/sitefactory/dev/2024-12-11_15-58-00_1d1561e/layers/builder/pages/admin/raffle/[id]/edit.vue?macro=true";
import { default as indexWUBQ3vOj1nMeta } from "/home/forge/deployments/sitefactory/dev/2024-12-11_15-58-00_1d1561e/layers/builder/pages/admin/raffle/[id]/index.vue?macro=true";
import { default as addqSllQ0uO0sMeta } from "/home/forge/deployments/sitefactory/dev/2024-12-11_15-58-00_1d1561e/layers/builder/pages/admin/raffle/add.vue?macro=true";
import { default as rafflesuxuspyhHWmMeta } from "/home/forge/deployments/sitefactory/dev/2024-12-11_15-58-00_1d1561e/layers/builder/pages/admin/raffles.vue?macro=true";
import { default as indexFISXsT4o94Meta } from "/home/forge/deployments/sitefactory/dev/2024-12-11_15-58-00_1d1561e/apps/site-factory/pages/movie/[movieSlug]/index.vue?macro=true";
import { default as infosjBwRg6raihMeta } from "/home/forge/deployments/sitefactory/dev/2024-12-11_15-58-00_1d1561e/apps/site-factory/pages/movie/[movieSlug]/infos.vue?macro=true";
import { default as programIUaY46VJ2rMeta } from "/home/forge/deployments/sitefactory/dev/2024-12-11_15-58-00_1d1561e/apps/site-factory/pages/movie/[movieSlug]/program.vue?macro=true";
import { default as _91showSlug_93ohzgz95LZWMeta } from "/home/forge/deployments/sitefactory/dev/2024-12-11_15-58-00_1d1561e/apps/site-factory/pages/movie/[movieSlug]/show/[showSlug].vue?macro=true";
import { default as _91movieSlug_931aFkug7k4UMeta } from "/home/forge/deployments/sitefactory/dev/2024-12-11_15-58-00_1d1561e/apps/site-factory/pages/movie/[movieSlug].vue?macro=true";
export default [
  {
    name: "pageSlug___de",
    path: "/:pageSlug(.*)*",
    component: () => import("/home/forge/deployments/sitefactory/dev/2024-12-11_15-58-00_1d1561e/layers/cms/pages/[...pageSlug].vue").then(m => m.default || m)
  },
  {
    name: "pageSlug___es",
    path: "/es/:pageSlug(.*)*",
    component: () => import("/home/forge/deployments/sitefactory/dev/2024-12-11_15-58-00_1d1561e/layers/cms/pages/[...pageSlug].vue").then(m => m.default || m)
  },
  {
    name: "pageSlug___de",
    path: "/:pageSlug(.*)*",
    component: () => import("/home/forge/deployments/sitefactory/dev/2024-12-11_15-58-00_1d1561e/layers/cms/pages/[...pageSlug].vue").then(m => m.default || m)
  },
  {
    name: "pageSlug___es",
    path: "/es/:pageSlug(.*)*",
    component: () => import("/home/forge/deployments/sitefactory/dev/2024-12-11_15-58-00_1d1561e/layers/cms/pages/[...pageSlug].vue").then(m => m.default || m)
  },
  {
    name: "pageSlug___de",
    path: "/:pageSlug(.*)*",
    component: () => import("/home/forge/deployments/sitefactory/dev/2024-12-11_15-58-00_1d1561e/layers/cms/pages/[...pageSlug].vue").then(m => m.default || m)
  },
  {
    name: "pageSlug___es",
    path: "/es/:pageSlug(.*)*",
    component: () => import("/home/forge/deployments/sitefactory/dev/2024-12-11_15-58-00_1d1561e/layers/cms/pages/[...pageSlug].vue").then(m => m.default || m)
  },
  {
    name: "pageSlug___de",
    path: "/:pageSlug(.*)*",
    component: () => import("/home/forge/deployments/sitefactory/dev/2024-12-11_15-58-00_1d1561e/layers/cms/pages/[...pageSlug].vue").then(m => m.default || m)
  },
  {
    name: "pageSlug___es",
    path: "/es/:pageSlug(.*)*",
    component: () => import("/home/forge/deployments/sitefactory/dev/2024-12-11_15-58-00_1d1561e/layers/cms/pages/[...pageSlug].vue").then(m => m.default || m)
  },
  {
    name: "account-activate___de",
    path: "/konto/aktivieren",
    component: () => import("/home/forge/deployments/sitefactory/dev/2024-12-11_15-58-00_1d1561e/layers/base/pages/account/activate.vue").then(m => m.default || m)
  },
  {
    name: "account-activate___es",
    path: "/es/cuenta/activar",
    component: () => import("/home/forge/deployments/sitefactory/dev/2024-12-11_15-58-00_1d1561e/layers/base/pages/account/activate.vue").then(m => m.default || m)
  },
  {
    name: "account-activate___de",
    path: "/konto/aktivieren",
    component: () => import("/home/forge/deployments/sitefactory/dev/2024-12-11_15-58-00_1d1561e/layers/base/pages/account/activate.vue").then(m => m.default || m)
  },
  {
    name: "account-activate___es",
    path: "/es/cuenta/activar",
    component: () => import("/home/forge/deployments/sitefactory/dev/2024-12-11_15-58-00_1d1561e/layers/base/pages/account/activate.vue").then(m => m.default || m)
  },
  {
    name: "account-activate___de",
    path: "/konto/aktivieren",
    component: () => import("/home/forge/deployments/sitefactory/dev/2024-12-11_15-58-00_1d1561e/layers/base/pages/account/activate.vue").then(m => m.default || m)
  },
  {
    name: "account-activate___es",
    path: "/es/cuenta/activar",
    component: () => import("/home/forge/deployments/sitefactory/dev/2024-12-11_15-58-00_1d1561e/layers/base/pages/account/activate.vue").then(m => m.default || m)
  },
  {
    name: "account-activate___de",
    path: "/konto/aktivieren",
    component: () => import("/home/forge/deployments/sitefactory/dev/2024-12-11_15-58-00_1d1561e/layers/base/pages/account/activate.vue").then(m => m.default || m)
  },
  {
    name: "account-activate___es",
    path: "/es/cuenta/activar",
    component: () => import("/home/forge/deployments/sitefactory/dev/2024-12-11_15-58-00_1d1561e/layers/base/pages/account/activate.vue").then(m => m.default || m)
  },
  {
    name: "account___de",
    path: "/konto",
    component: () => import("/home/forge/deployments/sitefactory/dev/2024-12-11_15-58-00_1d1561e/layers/base/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account___es",
    path: "/es/cuenta",
    component: () => import("/home/forge/deployments/sitefactory/dev/2024-12-11_15-58-00_1d1561e/layers/base/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account___de",
    path: "/konto",
    component: () => import("/home/forge/deployments/sitefactory/dev/2024-12-11_15-58-00_1d1561e/layers/base/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account___es",
    path: "/es/cuenta",
    component: () => import("/home/forge/deployments/sitefactory/dev/2024-12-11_15-58-00_1d1561e/layers/base/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account___de",
    path: "/konto",
    component: () => import("/home/forge/deployments/sitefactory/dev/2024-12-11_15-58-00_1d1561e/layers/base/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account___es",
    path: "/es/cuenta",
    component: () => import("/home/forge/deployments/sitefactory/dev/2024-12-11_15-58-00_1d1561e/layers/base/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account___de",
    path: "/konto",
    component: () => import("/home/forge/deployments/sitefactory/dev/2024-12-11_15-58-00_1d1561e/layers/base/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account___es",
    path: "/es/cuenta",
    component: () => import("/home/forge/deployments/sitefactory/dev/2024-12-11_15-58-00_1d1561e/layers/base/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account-order-orderNumber-securityKey___de",
    path: "/konto/bestellung/:orderNumber/:securityKey",
    component: () => import("/home/forge/deployments/sitefactory/dev/2024-12-11_15-58-00_1d1561e/layers/base/pages/account/order/[orderNumber]/[securityKey].vue").then(m => m.default || m)
  },
  {
    name: "account-order-orderNumber-securityKey___es",
    path: "/es/cuenta/compra/:orderNumber/:securityKey",
    component: () => import("/home/forge/deployments/sitefactory/dev/2024-12-11_15-58-00_1d1561e/layers/base/pages/account/order/[orderNumber]/[securityKey].vue").then(m => m.default || m)
  },
  {
    name: "account-order-orderNumber-securityKey___de",
    path: "/konto/bestellung/:orderNumber/:securityKey",
    component: () => import("/home/forge/deployments/sitefactory/dev/2024-12-11_15-58-00_1d1561e/layers/base/pages/account/order/[orderNumber]/[securityKey].vue").then(m => m.default || m)
  },
  {
    name: "account-order-orderNumber-securityKey___es",
    path: "/es/cuenta/compra/:orderNumber/:securityKey",
    component: () => import("/home/forge/deployments/sitefactory/dev/2024-12-11_15-58-00_1d1561e/layers/base/pages/account/order/[orderNumber]/[securityKey].vue").then(m => m.default || m)
  },
  {
    name: "account-order-orderNumber-securityKey___de",
    path: "/konto/bestellung/:orderNumber/:securityKey",
    component: () => import("/home/forge/deployments/sitefactory/dev/2024-12-11_15-58-00_1d1561e/layers/base/pages/account/order/[orderNumber]/[securityKey].vue").then(m => m.default || m)
  },
  {
    name: "account-order-orderNumber-securityKey___es",
    path: "/es/cuenta/compra/:orderNumber/:securityKey",
    component: () => import("/home/forge/deployments/sitefactory/dev/2024-12-11_15-58-00_1d1561e/layers/base/pages/account/order/[orderNumber]/[securityKey].vue").then(m => m.default || m)
  },
  {
    name: "account-order-orderNumber-securityKey___de",
    path: "/konto/bestellung/:orderNumber/:securityKey",
    component: () => import("/home/forge/deployments/sitefactory/dev/2024-12-11_15-58-00_1d1561e/layers/base/pages/account/order/[orderNumber]/[securityKey].vue").then(m => m.default || m)
  },
  {
    name: "account-order-orderNumber-securityKey___es",
    path: "/es/cuenta/compra/:orderNumber/:securityKey",
    component: () => import("/home/forge/deployments/sitefactory/dev/2024-12-11_15-58-00_1d1561e/layers/base/pages/account/order/[orderNumber]/[securityKey].vue").then(m => m.default || m)
  },
  {
    name: "account-password-new___de",
    path: "/konto/passwort/neu",
    component: () => import("/home/forge/deployments/sitefactory/dev/2024-12-11_15-58-00_1d1561e/layers/base/pages/account/password/new.vue").then(m => m.default || m)
  },
  {
    name: "account-password-new___es",
    path: "/es/cuenta/contrasena/nueva",
    component: () => import("/home/forge/deployments/sitefactory/dev/2024-12-11_15-58-00_1d1561e/layers/base/pages/account/password/new.vue").then(m => m.default || m)
  },
  {
    name: "account-password-new___de",
    path: "/konto/passwort/neu",
    component: () => import("/home/forge/deployments/sitefactory/dev/2024-12-11_15-58-00_1d1561e/layers/base/pages/account/password/new.vue").then(m => m.default || m)
  },
  {
    name: "account-password-new___es",
    path: "/es/cuenta/contrasena/nueva",
    component: () => import("/home/forge/deployments/sitefactory/dev/2024-12-11_15-58-00_1d1561e/layers/base/pages/account/password/new.vue").then(m => m.default || m)
  },
  {
    name: "account-password-new___de",
    path: "/konto/passwort/neu",
    component: () => import("/home/forge/deployments/sitefactory/dev/2024-12-11_15-58-00_1d1561e/layers/base/pages/account/password/new.vue").then(m => m.default || m)
  },
  {
    name: "account-password-new___es",
    path: "/es/cuenta/contrasena/nueva",
    component: () => import("/home/forge/deployments/sitefactory/dev/2024-12-11_15-58-00_1d1561e/layers/base/pages/account/password/new.vue").then(m => m.default || m)
  },
  {
    name: "account-password-new___de",
    path: "/konto/passwort/neu",
    component: () => import("/home/forge/deployments/sitefactory/dev/2024-12-11_15-58-00_1d1561e/layers/base/pages/account/password/new.vue").then(m => m.default || m)
  },
  {
    name: "account-password-new___es",
    path: "/es/cuenta/contrasena/nueva",
    component: () => import("/home/forge/deployments/sitefactory/dev/2024-12-11_15-58-00_1d1561e/layers/base/pages/account/password/new.vue").then(m => m.default || m)
  },
  {
    name: "account-watchlist___de",
    path: "/konto/merkliste",
    component: () => import("/home/forge/deployments/sitefactory/dev/2024-12-11_15-58-00_1d1561e/layers/base/pages/account/watchlist.vue").then(m => m.default || m)
  },
  {
    name: "account-watchlist___es",
    path: "/es/cuenta/marcadores",
    component: () => import("/home/forge/deployments/sitefactory/dev/2024-12-11_15-58-00_1d1561e/layers/base/pages/account/watchlist.vue").then(m => m.default || m)
  },
  {
    name: "account-watchlist___de",
    path: "/konto/merkliste",
    component: () => import("/home/forge/deployments/sitefactory/dev/2024-12-11_15-58-00_1d1561e/layers/base/pages/account/watchlist.vue").then(m => m.default || m)
  },
  {
    name: "account-watchlist___es",
    path: "/es/cuenta/marcadores",
    component: () => import("/home/forge/deployments/sitefactory/dev/2024-12-11_15-58-00_1d1561e/layers/base/pages/account/watchlist.vue").then(m => m.default || m)
  },
  {
    name: "account-watchlist___de",
    path: "/konto/merkliste",
    component: () => import("/home/forge/deployments/sitefactory/dev/2024-12-11_15-58-00_1d1561e/layers/base/pages/account/watchlist.vue").then(m => m.default || m)
  },
  {
    name: "account-watchlist___es",
    path: "/es/cuenta/marcadores",
    component: () => import("/home/forge/deployments/sitefactory/dev/2024-12-11_15-58-00_1d1561e/layers/base/pages/account/watchlist.vue").then(m => m.default || m)
  },
  {
    name: "account-watchlist___de",
    path: "/konto/merkliste",
    component: () => import("/home/forge/deployments/sitefactory/dev/2024-12-11_15-58-00_1d1561e/layers/base/pages/account/watchlist.vue").then(m => m.default || m)
  },
  {
    name: "account-watchlist___es",
    path: "/es/cuenta/marcadores",
    component: () => import("/home/forge/deployments/sitefactory/dev/2024-12-11_15-58-00_1d1561e/layers/base/pages/account/watchlist.vue").then(m => m.default || m)
  },
  {
    name: "admin___de",
    path: "/admin",
    meta: indexjOYkhPdoREMeta || {},
    component: () => import("/home/forge/deployments/sitefactory/dev/2024-12-11_15-58-00_1d1561e/layers/builder/pages/admin/index.vue").then(m => m.default || m)
  },
  {
    name: "admin___es",
    path: "/es/admin",
    meta: indexjOYkhPdoREMeta || {},
    component: () => import("/home/forge/deployments/sitefactory/dev/2024-12-11_15-58-00_1d1561e/layers/builder/pages/admin/index.vue").then(m => m.default || m)
  },
  {
    name: "admin___de",
    path: "/admin",
    meta: indexjOYkhPdoREMeta || {},
    component: () => import("/home/forge/deployments/sitefactory/dev/2024-12-11_15-58-00_1d1561e/layers/builder/pages/admin/index.vue").then(m => m.default || m)
  },
  {
    name: "admin___es",
    path: "/es/admin",
    meta: indexjOYkhPdoREMeta || {},
    component: () => import("/home/forge/deployments/sitefactory/dev/2024-12-11_15-58-00_1d1561e/layers/builder/pages/admin/index.vue").then(m => m.default || m)
  },
  {
    name: "admin___de",
    path: "/admin",
    meta: indexjOYkhPdoREMeta || {},
    component: () => import("/home/forge/deployments/sitefactory/dev/2024-12-11_15-58-00_1d1561e/layers/builder/pages/admin/index.vue").then(m => m.default || m)
  },
  {
    name: "admin___es",
    path: "/es/admin",
    meta: indexjOYkhPdoREMeta || {},
    component: () => import("/home/forge/deployments/sitefactory/dev/2024-12-11_15-58-00_1d1561e/layers/builder/pages/admin/index.vue").then(m => m.default || m)
  },
  {
    name: "admin___de",
    path: "/admin",
    meta: indexjOYkhPdoREMeta || {},
    component: () => import("/home/forge/deployments/sitefactory/dev/2024-12-11_15-58-00_1d1561e/layers/builder/pages/admin/index.vue").then(m => m.default || m)
  },
  {
    name: "admin___es",
    path: "/es/admin",
    meta: indexjOYkhPdoREMeta || {},
    component: () => import("/home/forge/deployments/sitefactory/dev/2024-12-11_15-58-00_1d1561e/layers/builder/pages/admin/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-id-edit___de",
    path: "/admin/raffle/:id()/edit",
    meta: editYAmfzVDVRGMeta || {},
    component: () => import("/home/forge/deployments/sitefactory/dev/2024-12-11_15-58-00_1d1561e/layers/builder/pages/admin/raffle/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-id-edit___es",
    path: "/es/admin/raffle/:id()/edit",
    meta: editYAmfzVDVRGMeta || {},
    component: () => import("/home/forge/deployments/sitefactory/dev/2024-12-11_15-58-00_1d1561e/layers/builder/pages/admin/raffle/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-id-edit___de",
    path: "/admin/raffle/:id()/edit",
    meta: editYAmfzVDVRGMeta || {},
    component: () => import("/home/forge/deployments/sitefactory/dev/2024-12-11_15-58-00_1d1561e/layers/builder/pages/admin/raffle/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-id-edit___es",
    path: "/es/admin/raffle/:id()/edit",
    meta: editYAmfzVDVRGMeta || {},
    component: () => import("/home/forge/deployments/sitefactory/dev/2024-12-11_15-58-00_1d1561e/layers/builder/pages/admin/raffle/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-id-edit___de",
    path: "/admin/raffle/:id()/edit",
    meta: editYAmfzVDVRGMeta || {},
    component: () => import("/home/forge/deployments/sitefactory/dev/2024-12-11_15-58-00_1d1561e/layers/builder/pages/admin/raffle/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-id-edit___es",
    path: "/es/admin/raffle/:id()/edit",
    meta: editYAmfzVDVRGMeta || {},
    component: () => import("/home/forge/deployments/sitefactory/dev/2024-12-11_15-58-00_1d1561e/layers/builder/pages/admin/raffle/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-id-edit___de",
    path: "/admin/raffle/:id()/edit",
    meta: editYAmfzVDVRGMeta || {},
    component: () => import("/home/forge/deployments/sitefactory/dev/2024-12-11_15-58-00_1d1561e/layers/builder/pages/admin/raffle/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-id-edit___es",
    path: "/es/admin/raffle/:id()/edit",
    meta: editYAmfzVDVRGMeta || {},
    component: () => import("/home/forge/deployments/sitefactory/dev/2024-12-11_15-58-00_1d1561e/layers/builder/pages/admin/raffle/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-id___de",
    path: "/admin/raffle/:id",
    meta: indexWUBQ3vOj1nMeta || {},
    component: () => import("/home/forge/deployments/sitefactory/dev/2024-12-11_15-58-00_1d1561e/layers/builder/pages/admin/raffle/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-id___es",
    path: "/es/admin/raffle/:id",
    meta: indexWUBQ3vOj1nMeta || {},
    component: () => import("/home/forge/deployments/sitefactory/dev/2024-12-11_15-58-00_1d1561e/layers/builder/pages/admin/raffle/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-id___de",
    path: "/admin/raffle/:id",
    meta: indexWUBQ3vOj1nMeta || {},
    component: () => import("/home/forge/deployments/sitefactory/dev/2024-12-11_15-58-00_1d1561e/layers/builder/pages/admin/raffle/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-id___es",
    path: "/es/admin/raffle/:id",
    meta: indexWUBQ3vOj1nMeta || {},
    component: () => import("/home/forge/deployments/sitefactory/dev/2024-12-11_15-58-00_1d1561e/layers/builder/pages/admin/raffle/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-id___de",
    path: "/admin/raffle/:id",
    meta: indexWUBQ3vOj1nMeta || {},
    component: () => import("/home/forge/deployments/sitefactory/dev/2024-12-11_15-58-00_1d1561e/layers/builder/pages/admin/raffle/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-id___es",
    path: "/es/admin/raffle/:id",
    meta: indexWUBQ3vOj1nMeta || {},
    component: () => import("/home/forge/deployments/sitefactory/dev/2024-12-11_15-58-00_1d1561e/layers/builder/pages/admin/raffle/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-id___de",
    path: "/admin/raffle/:id",
    meta: indexWUBQ3vOj1nMeta || {},
    component: () => import("/home/forge/deployments/sitefactory/dev/2024-12-11_15-58-00_1d1561e/layers/builder/pages/admin/raffle/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-id___es",
    path: "/es/admin/raffle/:id",
    meta: indexWUBQ3vOj1nMeta || {},
    component: () => import("/home/forge/deployments/sitefactory/dev/2024-12-11_15-58-00_1d1561e/layers/builder/pages/admin/raffle/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-add___de",
    path: "/admin/raffle/add",
    meta: addqSllQ0uO0sMeta || {},
    component: () => import("/home/forge/deployments/sitefactory/dev/2024-12-11_15-58-00_1d1561e/layers/builder/pages/admin/raffle/add.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-add___es",
    path: "/es/admin/raffle/add",
    meta: addqSllQ0uO0sMeta || {},
    component: () => import("/home/forge/deployments/sitefactory/dev/2024-12-11_15-58-00_1d1561e/layers/builder/pages/admin/raffle/add.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-add___de",
    path: "/admin/raffle/add",
    meta: addqSllQ0uO0sMeta || {},
    component: () => import("/home/forge/deployments/sitefactory/dev/2024-12-11_15-58-00_1d1561e/layers/builder/pages/admin/raffle/add.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-add___es",
    path: "/es/admin/raffle/add",
    meta: addqSllQ0uO0sMeta || {},
    component: () => import("/home/forge/deployments/sitefactory/dev/2024-12-11_15-58-00_1d1561e/layers/builder/pages/admin/raffle/add.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-add___de",
    path: "/admin/raffle/add",
    meta: addqSllQ0uO0sMeta || {},
    component: () => import("/home/forge/deployments/sitefactory/dev/2024-12-11_15-58-00_1d1561e/layers/builder/pages/admin/raffle/add.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-add___es",
    path: "/es/admin/raffle/add",
    meta: addqSllQ0uO0sMeta || {},
    component: () => import("/home/forge/deployments/sitefactory/dev/2024-12-11_15-58-00_1d1561e/layers/builder/pages/admin/raffle/add.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-add___de",
    path: "/admin/raffle/add",
    meta: addqSllQ0uO0sMeta || {},
    component: () => import("/home/forge/deployments/sitefactory/dev/2024-12-11_15-58-00_1d1561e/layers/builder/pages/admin/raffle/add.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-add___es",
    path: "/es/admin/raffle/add",
    meta: addqSllQ0uO0sMeta || {},
    component: () => import("/home/forge/deployments/sitefactory/dev/2024-12-11_15-58-00_1d1561e/layers/builder/pages/admin/raffle/add.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffles___de",
    path: "/admin/raffles",
    meta: rafflesuxuspyhHWmMeta || {},
    component: () => import("/home/forge/deployments/sitefactory/dev/2024-12-11_15-58-00_1d1561e/layers/builder/pages/admin/raffles.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffles___es",
    path: "/es/admin/raffles",
    meta: rafflesuxuspyhHWmMeta || {},
    component: () => import("/home/forge/deployments/sitefactory/dev/2024-12-11_15-58-00_1d1561e/layers/builder/pages/admin/raffles.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffles___de",
    path: "/admin/raffles",
    meta: rafflesuxuspyhHWmMeta || {},
    component: () => import("/home/forge/deployments/sitefactory/dev/2024-12-11_15-58-00_1d1561e/layers/builder/pages/admin/raffles.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffles___es",
    path: "/es/admin/raffles",
    meta: rafflesuxuspyhHWmMeta || {},
    component: () => import("/home/forge/deployments/sitefactory/dev/2024-12-11_15-58-00_1d1561e/layers/builder/pages/admin/raffles.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffles___de",
    path: "/admin/raffles",
    meta: rafflesuxuspyhHWmMeta || {},
    component: () => import("/home/forge/deployments/sitefactory/dev/2024-12-11_15-58-00_1d1561e/layers/builder/pages/admin/raffles.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffles___es",
    path: "/es/admin/raffles",
    meta: rafflesuxuspyhHWmMeta || {},
    component: () => import("/home/forge/deployments/sitefactory/dev/2024-12-11_15-58-00_1d1561e/layers/builder/pages/admin/raffles.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffles___de",
    path: "/admin/raffles",
    meta: rafflesuxuspyhHWmMeta || {},
    component: () => import("/home/forge/deployments/sitefactory/dev/2024-12-11_15-58-00_1d1561e/layers/builder/pages/admin/raffles.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffles___es",
    path: "/es/admin/raffles",
    meta: rafflesuxuspyhHWmMeta || {},
    component: () => import("/home/forge/deployments/sitefactory/dev/2024-12-11_15-58-00_1d1561e/layers/builder/pages/admin/raffles.vue").then(m => m.default || m)
  },
  {
    name: _91movieSlug_931aFkug7k4UMeta?.name,
    path: "/film/:movieSlug",
    component: () => import("/home/forge/deployments/sitefactory/dev/2024-12-11_15-58-00_1d1561e/apps/site-factory/pages/movie/[movieSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "movie-movieSlug___de",
    path: "",
    component: () => import("/home/forge/deployments/sitefactory/dev/2024-12-11_15-58-00_1d1561e/apps/site-factory/pages/movie/[movieSlug]/index.vue").then(m => m.default || m)
  },
  {
    name: "movie-movieSlug-infos___de",
    path: "/film/:movieSlug/filminfos",
    component: () => import("/home/forge/deployments/sitefactory/dev/2024-12-11_15-58-00_1d1561e/apps/site-factory/pages/movie/[movieSlug]/infos.vue").then(m => m.default || m)
  },
  {
    name: "movie-movieSlug-program___de",
    path: "/film/:movieSlug/spielzeiten",
    component: () => import("/home/forge/deployments/sitefactory/dev/2024-12-11_15-58-00_1d1561e/apps/site-factory/pages/movie/[movieSlug]/program.vue").then(m => m.default || m)
  },
  {
    name: "movie-movieSlug-show-showSlug___de",
    path: "/film/:movieSlug/vorstellung/:showSlug",
    component: () => import("/home/forge/deployments/sitefactory/dev/2024-12-11_15-58-00_1d1561e/apps/site-factory/pages/movie/[movieSlug]/show/[showSlug].vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91movieSlug_931aFkug7k4UMeta?.name,
    path: "/es/movie/:movieSlug()",
    component: () => import("/home/forge/deployments/sitefactory/dev/2024-12-11_15-58-00_1d1561e/apps/site-factory/pages/movie/[movieSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "movie-movieSlug___es",
    path: "",
    component: () => import("/home/forge/deployments/sitefactory/dev/2024-12-11_15-58-00_1d1561e/apps/site-factory/pages/movie/[movieSlug]/index.vue").then(m => m.default || m)
  },
  {
    name: "movie-movieSlug-infos___es",
    path: "infos",
    component: () => import("/home/forge/deployments/sitefactory/dev/2024-12-11_15-58-00_1d1561e/apps/site-factory/pages/movie/[movieSlug]/infos.vue").then(m => m.default || m)
  },
  {
    name: "movie-movieSlug-program___es",
    path: "program",
    component: () => import("/home/forge/deployments/sitefactory/dev/2024-12-11_15-58-00_1d1561e/apps/site-factory/pages/movie/[movieSlug]/program.vue").then(m => m.default || m)
  },
  {
    name: "movie-movieSlug-show-showSlug___es",
    path: "show/:showSlug()",
    component: () => import("/home/forge/deployments/sitefactory/dev/2024-12-11_15-58-00_1d1561e/apps/site-factory/pages/movie/[movieSlug]/show/[showSlug].vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91movieSlug_931aFkug7k4UMeta?.name,
    path: "/film/:movieSlug",
    component: () => import("/home/forge/deployments/sitefactory/dev/2024-12-11_15-58-00_1d1561e/apps/site-factory/pages/movie/[movieSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "movie-movieSlug___de",
    path: "",
    component: () => import("/home/forge/deployments/sitefactory/dev/2024-12-11_15-58-00_1d1561e/apps/site-factory/pages/movie/[movieSlug]/index.vue").then(m => m.default || m)
  },
  {
    name: "movie-movieSlug-infos___de",
    path: "/film/:movieSlug/filminfos",
    component: () => import("/home/forge/deployments/sitefactory/dev/2024-12-11_15-58-00_1d1561e/apps/site-factory/pages/movie/[movieSlug]/infos.vue").then(m => m.default || m)
  },
  {
    name: "movie-movieSlug-program___de",
    path: "/film/:movieSlug/spielzeiten",
    component: () => import("/home/forge/deployments/sitefactory/dev/2024-12-11_15-58-00_1d1561e/apps/site-factory/pages/movie/[movieSlug]/program.vue").then(m => m.default || m)
  },
  {
    name: "movie-movieSlug-show-showSlug___de",
    path: "/film/:movieSlug/vorstellung/:showSlug",
    component: () => import("/home/forge/deployments/sitefactory/dev/2024-12-11_15-58-00_1d1561e/apps/site-factory/pages/movie/[movieSlug]/show/[showSlug].vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91movieSlug_931aFkug7k4UMeta?.name,
    path: "/es/movie/:movieSlug()",
    component: () => import("/home/forge/deployments/sitefactory/dev/2024-12-11_15-58-00_1d1561e/apps/site-factory/pages/movie/[movieSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "movie-movieSlug___es",
    path: "",
    component: () => import("/home/forge/deployments/sitefactory/dev/2024-12-11_15-58-00_1d1561e/apps/site-factory/pages/movie/[movieSlug]/index.vue").then(m => m.default || m)
  },
  {
    name: "movie-movieSlug-infos___es",
    path: "infos",
    component: () => import("/home/forge/deployments/sitefactory/dev/2024-12-11_15-58-00_1d1561e/apps/site-factory/pages/movie/[movieSlug]/infos.vue").then(m => m.default || m)
  },
  {
    name: "movie-movieSlug-program___es",
    path: "program",
    component: () => import("/home/forge/deployments/sitefactory/dev/2024-12-11_15-58-00_1d1561e/apps/site-factory/pages/movie/[movieSlug]/program.vue").then(m => m.default || m)
  },
  {
    name: "movie-movieSlug-show-showSlug___es",
    path: "show/:showSlug()",
    component: () => import("/home/forge/deployments/sitefactory/dev/2024-12-11_15-58-00_1d1561e/apps/site-factory/pages/movie/[movieSlug]/show/[showSlug].vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91movieSlug_931aFkug7k4UMeta?.name,
    path: "/film/:movieSlug",
    component: () => import("/home/forge/deployments/sitefactory/dev/2024-12-11_15-58-00_1d1561e/apps/site-factory/pages/movie/[movieSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "movie-movieSlug___de",
    path: "",
    component: () => import("/home/forge/deployments/sitefactory/dev/2024-12-11_15-58-00_1d1561e/apps/site-factory/pages/movie/[movieSlug]/index.vue").then(m => m.default || m)
  },
  {
    name: "movie-movieSlug-infos___de",
    path: "/film/:movieSlug/filminfos",
    component: () => import("/home/forge/deployments/sitefactory/dev/2024-12-11_15-58-00_1d1561e/apps/site-factory/pages/movie/[movieSlug]/infos.vue").then(m => m.default || m)
  },
  {
    name: "movie-movieSlug-program___de",
    path: "/film/:movieSlug/spielzeiten",
    component: () => import("/home/forge/deployments/sitefactory/dev/2024-12-11_15-58-00_1d1561e/apps/site-factory/pages/movie/[movieSlug]/program.vue").then(m => m.default || m)
  },
  {
    name: "movie-movieSlug-show-showSlug___de",
    path: "/film/:movieSlug/vorstellung/:showSlug",
    component: () => import("/home/forge/deployments/sitefactory/dev/2024-12-11_15-58-00_1d1561e/apps/site-factory/pages/movie/[movieSlug]/show/[showSlug].vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91movieSlug_931aFkug7k4UMeta?.name,
    path: "/es/movie/:movieSlug()",
    component: () => import("/home/forge/deployments/sitefactory/dev/2024-12-11_15-58-00_1d1561e/apps/site-factory/pages/movie/[movieSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "movie-movieSlug___es",
    path: "",
    component: () => import("/home/forge/deployments/sitefactory/dev/2024-12-11_15-58-00_1d1561e/apps/site-factory/pages/movie/[movieSlug]/index.vue").then(m => m.default || m)
  },
  {
    name: "movie-movieSlug-infos___es",
    path: "infos",
    component: () => import("/home/forge/deployments/sitefactory/dev/2024-12-11_15-58-00_1d1561e/apps/site-factory/pages/movie/[movieSlug]/infos.vue").then(m => m.default || m)
  },
  {
    name: "movie-movieSlug-program___es",
    path: "program",
    component: () => import("/home/forge/deployments/sitefactory/dev/2024-12-11_15-58-00_1d1561e/apps/site-factory/pages/movie/[movieSlug]/program.vue").then(m => m.default || m)
  },
  {
    name: "movie-movieSlug-show-showSlug___es",
    path: "show/:showSlug()",
    component: () => import("/home/forge/deployments/sitefactory/dev/2024-12-11_15-58-00_1d1561e/apps/site-factory/pages/movie/[movieSlug]/show/[showSlug].vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91movieSlug_931aFkug7k4UMeta?.name,
    path: "/film/:movieSlug",
    component: () => import("/home/forge/deployments/sitefactory/dev/2024-12-11_15-58-00_1d1561e/apps/site-factory/pages/movie/[movieSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "movie-movieSlug___de",
    path: "",
    component: () => import("/home/forge/deployments/sitefactory/dev/2024-12-11_15-58-00_1d1561e/apps/site-factory/pages/movie/[movieSlug]/index.vue").then(m => m.default || m)
  },
  {
    name: "movie-movieSlug-infos___de",
    path: "/film/:movieSlug/filminfos",
    component: () => import("/home/forge/deployments/sitefactory/dev/2024-12-11_15-58-00_1d1561e/apps/site-factory/pages/movie/[movieSlug]/infos.vue").then(m => m.default || m)
  },
  {
    name: "movie-movieSlug-program___de",
    path: "/film/:movieSlug/spielzeiten",
    component: () => import("/home/forge/deployments/sitefactory/dev/2024-12-11_15-58-00_1d1561e/apps/site-factory/pages/movie/[movieSlug]/program.vue").then(m => m.default || m)
  },
  {
    name: "movie-movieSlug-show-showSlug___de",
    path: "/film/:movieSlug/vorstellung/:showSlug",
    component: () => import("/home/forge/deployments/sitefactory/dev/2024-12-11_15-58-00_1d1561e/apps/site-factory/pages/movie/[movieSlug]/show/[showSlug].vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91movieSlug_931aFkug7k4UMeta?.name,
    path: "/es/movie/:movieSlug()",
    component: () => import("/home/forge/deployments/sitefactory/dev/2024-12-11_15-58-00_1d1561e/apps/site-factory/pages/movie/[movieSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "movie-movieSlug___es",
    path: "",
    component: () => import("/home/forge/deployments/sitefactory/dev/2024-12-11_15-58-00_1d1561e/apps/site-factory/pages/movie/[movieSlug]/index.vue").then(m => m.default || m)
  },
  {
    name: "movie-movieSlug-infos___es",
    path: "infos",
    component: () => import("/home/forge/deployments/sitefactory/dev/2024-12-11_15-58-00_1d1561e/apps/site-factory/pages/movie/[movieSlug]/infos.vue").then(m => m.default || m)
  },
  {
    name: "movie-movieSlug-program___es",
    path: "program",
    component: () => import("/home/forge/deployments/sitefactory/dev/2024-12-11_15-58-00_1d1561e/apps/site-factory/pages/movie/[movieSlug]/program.vue").then(m => m.default || m)
  },
  {
    name: "movie-movieSlug-show-showSlug___es",
    path: "show/:showSlug()",
    component: () => import("/home/forge/deployments/sitefactory/dev/2024-12-11_15-58-00_1d1561e/apps/site-factory/pages/movie/[movieSlug]/show/[showSlug].vue").then(m => m.default || m)
  }
]
  }
]